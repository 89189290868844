<template>
  <div class="h-100">
    <router-view />
  </div>
</template>

<style>
@import "./assets/styles/styles.min.css";
@import "./assets/fonts/fontawesome-all.min.css";
@import "./assets/bootstrap/css/bootstrap.min.css";
body {
  height: 100vh;
}
#app {
  height: 100vh;
}
</style>
