<template>
  <div class="home">
    <div class="home text-center m-5">
      <form class="p-5 smooth-border">
        <h1 class="mb-3">Auftrags Erfassung<br /></h1>
        <div style="font-size: 1.5rem;">
          <div><span class="fw-bold">Datum</span><input id="homeDate" @keyup.enter="onEnter" type="text" class="form-control text-center" required v-model="data.date" autofocus /></div>
          <div><span class="fw-bold">Firma</span><input id="homeCompany" @keyup.enter="onEnter" type="text" class="form-control text-center" required v-model="data.company" /></div>
          <div><span class="fw-bold">Artikel</span><input id="homeProduct" @keyup.enter="onEnter" type="text" class="form-control text-center" required v-model="data.product" /></div>
          <div><span class="fw-bold">Anzahl</span><input id="homeAmount" @keyup.enter="onEnter" type="text" class="form-control text-center" required v-model="data.amount" /></div>
        </div>
        <div class="mt-3">
          <div class="row">
            <div v-on:click="logout" class="col"><button class="btn btn-primary w-100" type="button"><i class="fas fa-power-off"></i></button></div>
            <div v-on:click="deleteLast" class="col"><button class="btn btn-primary w-100" type="button"><i class="fas fa-trash"></i></button></div>
            <div v-on:click="this.$router.push('List')" class="col"><button class="btn btn-primary w-100" type="button"><i class="fas fa-print"></i></button></div>
          </div>
          <span v-show="data.error" class="text-danger fw-bold mt-3">Action failed</span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { reactive } from 'vue'
import { useStore } from 'vuex'
import router from "@/router"

export default {
  name: "Home",
  setup () {

    const store = useStore()
    const data = reactive({
      date: store.getters['App/getDate'],
      company: null,
      product: null,
      amount: null,
      error: false
    })

    const save = () => {
      console.log('Save data', data)
      // resetData()
      // return
      const params = new URLSearchParams()
      params.append('user', (store.getters['App/getSession'])?.userID)
      params.append('date', data.date)
      params.append('company', data.company)
      params.append('product', data.product)
      params.append('amount', data.amount)
      axios.post('api/data/save.php', params)
      .then(response => {
        console.log(response)
        resetData()
      })
      .catch(error => {
        console.log(error)
        data.error = true
      })
    }

    const logout = () => {
      console.log('Logout')
      // router.push({ name: 'Login' })
      // return
      axios.post('api/account/logout.php')
      .then(response => {
        console.log(response)
        data.error = false
        store.commit('App/setSession', null),
        router.push({ name: 'Login' })
      })
      .catch(error => {
        console.log(error)
        data.error = true
      })
    }

    const deleteLast = () => {
      console.log('Deleting last element')
      // resetData()
      // return
      const params = new URLSearchParams()
      params.append('user', (store.getters['App/getSession']).userID)
      axios.post('api/data/delete.php', params)
      .then(response => {
        console.log(response)
        alert('Deleted last entry')
        data.error = false
      })
      .catch(error => {
        console.log(error)
        data.error = true
      })
    }

    const resetData = () => {
      data.date = store.getters['App/getDate']
      // data.company = ''
      data.product = ''
      data.amount = ''
      data.error = false
    }

    const onEnter = (event) => {
      // Jump to next input
      let next = ''
      switch (event.target.id) {
        case 'homeDate':
          next = 'homeCompany'
          break
        case 'homeCompany':
          next = 'homeProduct'
          break
        case 'homeProduct':
          next = 'homeAmount'
          break
        default:
          next = 'homeProduct'
          break
      }
      document.getElementById(next).focus()
      // If last element then submit data
      if (event.target.id == 'homeAmount') save()
    }

    return {
      data,
      logout,
      deleteLast,
      onEnter
    }
  }
};
</script>
