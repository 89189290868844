const state = {
  session: null,
}

const getters = {
  getSession(state) {
    return state.session;
  },
  getDate(state, type) {
    const date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    const year = date.getFullYear()
    if (day.toString().length < 2) day = '0' + day
    if (month.toString().length < 2) month = '0' + month
    return `${day}.${month}.${year}`
  },
  getDateUS() {
    const date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    const year = date.getFullYear()
    if (day.toString().length < 2) day = '0' + day
    if (month.toString().length < 2) month = '0' + month
    return `${year}-${month}-${day}`
    // Needs to be this format (HTML input date)
    // 2021-11-02
  }
}

const mutations = {
  setSession(state, value) {
    console.log('Setting session')
    state.session = value
  },
}

const actions = {
  // Code ...
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
