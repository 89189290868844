const state = {
  test: "",
};

const getters = {
  getTest(state) {
    return state.test;
  },
};

const mutations = {
  setTest(state, value) {
    state.test = value;
  },
};

const actions = {
  async actionTest() {
    // Code ...
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
