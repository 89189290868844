<template>
  <div class="login h-100">
    <div class="jumbotron d-flex align-items-center h-100">
      <div class="container text-center">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <form class="p-5">
              <h1 class="text-center"><i class="fas fa-user"></i></h1>
              <input v-model="credentials.email" type="text" class="form-control" placeholder="email" autofocus />
              <input v-model="credentials.password" @keyup.enter="submitLogin" type="password" class="form-control mt-3" placeholder="password" />
              <button v-on:click="submitLogin" class="btn btn-primary w-100 mt-3" type="button">Login</button>
              <span v-show="credentials.error" class="text-danger fw-bold mt-3">Login failed</span>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { reactive } from 'vue'
import { useStore } from 'vuex'
import router from "@/router"

export default {
  name: 'Login',
  setup() {

    const store = useStore()
    const credentials = reactive({ email: null, password: null, error: false })

    const submitLogin = () => {
      // store.commit('App/setSession', { login: true, userID: 1 }),
      // router.push({ name: 'Home' })
      // return
      console.log('Login data', credentials)
      const params = new URLSearchParams()
      params.append('email', credentials.email)
      params.append('password', credentials.password)
      axios.post('api/account/login.php', params)
      .then(response => {
        console.log(response)
        credentials.error = false
        store.commit('App/setSession', { login: true, userID: response.data }),
        router.push({ name: 'Home' })
      })
      .catch(error => {
        console.log(error)
        credentials.error = true
      })
    }

    return {
      credentials,
      submitLogin
    }
  }
}
</script>
